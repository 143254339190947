import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SizeEnum } from 'src/app/modules/app-core/models/size.model';
import { selectRouteParam } from 'src/app/modules/app-core/store/router/router.selectors';
import {
  featureKey,
  IItemState,
  itemAdapter,
  itemDisplayAdapter,
} from './state';

const selectItemState = createFeatureSelector<IItemState>(featureKey);

/* All items  */
const selectAllItemsState = createSelector(
  selectItemState,
  (state) => state.allItems
);

const { selectAll: allItems, selectEntities: displayItemEntities } =
  itemDisplayAdapter.getSelectors(selectAllItemsState);

export const selectAllItems = createSelector(allItems, (items) => items);

export const selectItemsAllItemsEntities = createSelector(
  displayItemEntities,
  (items) => items
);

export const selectIsAllItemsLoading = createSelector(
  selectItemState,
  (state) => state.loading.all
);

export const selectItemById = (id: string) =>
  createSelector(displayItemEntities, (items) => items[id]);

/* Featured items  */
const selectFeaturedItemsState = createSelector(
  selectItemState,
  (state) => state.featuredItems
);

const { selectAll: featuredItems } = itemDisplayAdapter.getSelectors(
  selectFeaturedItemsState
);

export const selectItemsFeaturedItems = createSelector(
  featuredItems,
  (items) => items
);

export const selectItemsIsFeaturedItemsLoading = createSelector(
  selectItemState,
  (state) => state.loading.featured
);

/* Item by id */
const itemDetailsState = createSelector(selectItemState, (state) => state.item);

const { selectEntities: itemEntities } =
  itemAdapter.getSelectors(itemDetailsState);

export const selectItemDetailsById = (id: string) =>
  createSelector(itemEntities, (items) => items?.[id]);

export const selectIsItemLoading = (id: string) =>
  createSelector(selectItemState, (state) => state.loading.item[id]);

export const selectItemAllImagesById = (id: string) =>
  createSelector(itemEntities, (items) => items?.[id]?.image);

export const selectItemCurrentItem = createSelector(
  selectRouteParam('id'),
  itemEntities,
  (id, items) => items[id]
);

export const selectItemCurrentItemLoading = createSelector(
  selectRouteParam('id'),
  selectItemState,
  (id, state) => state.loading.item[id]
);

export const selectItemCurrentItemAvailableSizes = createSelector(
  selectItemCurrentItem,
  (item) =>
    Object.keys(item?.data?.size || {}).reduce(
      (acc: SizeEnum[], size: SizeEnum) => {
        if (item.data.size[size] > 0) {
          acc.push(size);
        }
        return acc;
      },
      []
    )
);
