import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly APP_STORE = 'appStore';

  setData(key: string, value: any, expirySeconds?: number): void {
    // default expiry 2 days
    const storeData = this.getStorageData();
    storeData[key] = {
      value,
      expiry: expirySeconds ? Date.now() + expirySeconds * 1000 : null,
    };
    this.setStorageData(storeData);
  }

  getData(key: string): any {
    const storeData = this.getStorageData();
    const data = storeData?.[key];
    return data && !this.isDataExpired(data.expiry) && data.value;
  }

  private isDataExpired(timestamp: number): boolean {
    return Boolean(timestamp) && Date.now() > timestamp;
  }

  private setStorageData(data: any) {
    localStorage.setItem(this.APP_STORE, this.encodeData(data));
  }

  private getStorageData() {
    return this.decodeData(localStorage.getItem(this.APP_STORE));
  }

  private encodeData(data: any) {
    return btoa(JSON.stringify(data));
  }

  private decodeData(data: string | null): Record<string, any> {
    try {
      const decodedJson = data ? atob(data) : '{}';
      return JSON.parse(decodedJson);
    } catch (err) {
      return {};
    }
  }
}
