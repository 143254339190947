import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class AppLogoComponent {
  @Input() logoSize? = 'default';
}
