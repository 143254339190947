<ng-container
  *ngIf="{
    isLoggedIn: isLoggedIn$ | async
  } as d"
>
  <button
    tuiIconButton
    icon="app-home"
    [appearance]="rlaHome.isActive ? 'primary' : 'flat'"
    [routerLink]="['/', 'home']"
    routerLinkActive
    size="m"
    [routerLinkActiveOptions]="{ exact: false }"
    #rlaHome="routerLinkActive"
  ></button>
  <button
    tuiIconButton
    icon="app-tshirt"
    [appearance]="rlaCollections.isActive ? 'primary' : 'flat'"
    [routerLink]="['/', 'item', 'collections']"
    routerLinkActive
    size="m"
    [routerLinkActiveOptions]="{ exact: false }"
    #rlaCollections="routerLinkActive"
  ></button>
  <cart-icon [forQuickAccess]="true"></cart-icon>
  <button
    *ngIf="d.isLoggedIn"
    tuiIconButton
    icon="app-user"
    [appearance]="rlaProfile.isActive ? 'primary' : 'flat'"
    [routerLink]="['/', 'account']"
    routerLinkActive
    size="m"
    [routerLinkActiveOptions]="{ exact: false }"
    #rlaProfile="routerLinkActive"
  ></button>
  <button
    *ngIf="!d.isLoggedIn"
    tuiIconButton
    icon="app-user"
    size="m"
    [appearance]="'flat'"
    (click)="openSignInModal()"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: false }"
    #rlaLogin="routerLinkActive"
  ></button>
</ng-container>
