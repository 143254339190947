import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IItem, IItemDisplay } from '../interfaces/app-item.model';

export const itemDisplayAdapter = createEntityAdapter<IItemDisplay>({
  selectId: (item) => item.id,
});

export const itemAdapter = createEntityAdapter<IItem>({
  selectId: (item) => item.id,
});

export interface IItemState {
  allItems: EntityState<IItemDisplay>;
  featuredItems: EntityState<IItemDisplay>;
  item: EntityState<IItem>;
  loading: {
    all: boolean;
    featured: boolean;
    item: Record<string, boolean>;
  };
}

export const initialState: IItemState = {
  allItems: itemDisplayAdapter.getInitialState(),
  featuredItems: itemDisplayAdapter.getInitialState(),
  item: itemAdapter.getInitialState(),
  loading: {
    all: false,
    featured: false,
    item: {},
  },
};

export const featureKey = 'Products Core Store';
