<ng-container
  *ngIf="{
    isLoggedIn: isLoggedIn$ | async,
    isAdmin: isAdmin$ | async
  } as d"
>
  <button
    tuiButton
    [appearance]="rlaHome.isActive ? 'outline' : 'flat'"
    size="m"
    routerLinkActive
    [routerLink]="['/', 'home']"
    #rlaHome="routerLinkActive"
  >
    Home
  </button>
  <button
    tuiButton
    [appearance]="rlaCollections.isActive ? 'outline' : 'flat'"
    #rlaCollections="routerLinkActive"
    size="m"
    routerLinkActive
    [routerLink]="['/', 'item', 'collections']"
  >
    Collections
  </button>
  <button
    *ngIf="d.isLoggedIn"
    tuiButton
    [appearance]="rlaAccount.isActive ? 'outline' : 'flat'"
    #rlaAccount="routerLinkActive"
    size="m"
    routerLinkActive
    [routerLink]="['/', 'account']"
  >
    Account
  </button>
  <button
    *ngIf="!d.isLoggedIn"
    tuiButton
    [appearance]="rlaLogin.isActive ? 'outline' : 'flat'"
    #rlaLogin="routerLinkActive"
    size="m"
    routerLinkActive
    (click)="openSignInModal()"
  >
    Login
  </button>
  <button
    *ngIf="d.isAdmin"
    tuiButton
    [appearance]="rlaAdmin.isActive ? 'outline' : 'flat'"
    #rlaAdmin="routerLinkActive"
    size="m"
    routerLinkActive
    [routerLink]="['/', 'admin']"
  >
    Admin
  </button>
  <cart-icon></cart-icon>
</ng-container>
