import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  TuiAlertModule,
  TuiDialogModule,
  TuiSvgModule,
  TUI_SVG_SRC_PROCESSOR,
} from '@taiga-ui/core';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { AuthCoreModule } from '../auth/modules/auth-core/auth-core.module';
import { AppLogoComponent } from '../shared/components/app-logo/app-logo.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppPageLoaderComponent } from './components/app-page-loader/app-page-loader.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { QuickAccessBarComponent } from './components/quick-access-bar/quick-access-bar.component';
import { StickyHeaderDirective } from './directives/sticky-header.directive';
import { LayoutDefaultComponent } from './layouts/layout-default/layout-default.component';

@NgModule({
  imports: [
    TuiSvgModule,
    CommonModule,
    RouterModule,
    AuthCoreModule,
    TuiAlertModule,
    TuiDialogModule,
    AppLogoComponent,
    AppHeaderComponent,
    BreadcrumbsComponent,
    QuickAccessBarComponent,
  ],
  declarations: [
    AppFooterComponent,
    StickyHeaderDirective,
    LayoutDefaultComponent,
    AppPageLoaderComponent,
  ],
  exports: [
    LayoutDefaultComponent,
    BreadcrumbsComponent,
    AppPageLoaderComponent,
  ],
  providers: [
    {
      provide: TUI_SVG_SRC_PROCESSOR,
      useFactory: () => {
        return (src: string): string => {
          const myCustomPrefix = `app-`;
          return src.startsWith(myCustomPrefix)
            ? `assets/icons/${src.replace(myCustomPrefix, ``)}.svg`
            : src;
        };
      },
    },
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: 'Required',
        email: 'Invalid email',
        invalidEmail: 'Invalid email',
        invalidPhoneNumber: 'Invalid phone number',
        invalidPincode: 'Invalid pincode',
        maxlength: (context: { requiredLength: string }): string => {
          return `Exceeds ${context.requiredLength} characters`;
        },
        minlength: (context: { requiredLength: string }): string => {
          return `Should have atleast ${context.requiredLength} characters`;
        },
        pattern: 'Invalid value',
      },
    },
  ],
})
export class AppCoreModule {}
