import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppPageLoaderService } from '../../services/app-page-loader.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './app-page-loader.component.html',
  styleUrls: ['./app-page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // standalone: true,
  // imports: [CommonModule],
  // encapsulation: ViewEncapsulation.None,
})
export class AppPageLoaderComponent {
  readonly showLoader$ = this.loaderService.canShowLoader$;
  readonly isTransparent$ = this.loaderService.isTransparent$;

  constructor(private loaderService: AppPageLoaderService) {}
}
