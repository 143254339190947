import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductsCoreModule } from 'src/app/modules/app-items/modules/products-core/products-core.module';
import { AlertsModule } from 'src/app/modules/shared/modules/alerts/alerts.module';
import { CartService } from './services/cart.service';
import { CartEffects } from './store/cart.effects';
import { CartReducer } from './store/cart.reducers';
import { cartFeatureKey } from './store/cart.state';

@NgModule({
  imports: [
    AlertsModule,
    ProductsCoreModule,
    EffectsModule.forFeature([CartEffects]),
    StoreModule.forFeature(cartFeatureKey, CartReducer),
  ],
  providers: [CartService],
})
export class CartCoreModule {}
