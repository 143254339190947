import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { selectQueryParam } from 'src/app/modules/app-core/store/router/router.selectors';
import { AlertsService } from 'src/app/modules/shared/modules/alerts/services/alerts.service';
import { DialogsService } from 'src/app/modules/shared/modules/dialogs/services/dialogs.service';
import { AppErrorService } from 'src/app/modules/shared/services/app-error.service';
import { deepClone } from 'src/app/modules/shared/utils/deep-clone.utils';
import { loadResetPasswordModalComponent } from '../../../modals/reset-password/reset-password-modal-lazy-load.util';
import { loadSignInModalComponent } from '../../../modals/sign-in/sign-in-modal-lazy-load.util';
import { AuthApiService } from '../services/auth-api.service';
import { authActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private alertService: AlertsService,
    private errorService: AppErrorService,
    private dialogService: DialogsService,
    private authApiService: AuthApiService
  ) {}

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signIn),
      exhaustMap(({ email, password }) =>
        this.authApiService.signIn(email, password).pipe(
          map((user) => authActions.signInComplete({ user: deepClone(user) })),
          catchError((response) => {
            const errorMessage = this.errorService.parse(response);
            this.alertService.error(errorMessage).subscribe();
            return of(authActions.signInError({ error: errorMessage }));
          })
        )
      )
    )
  );

  signInWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signInWithGoogle),
      exhaustMap(() =>
        this.authApiService.googleSignin().pipe(
          map((user) => authActions.signInComplete({ user: deepClone(user) })),
          catchError((response) => {
            const errorMessage = this.errorService.parse(response);
            this.alertService.error(errorMessage).subscribe();
            return of(authActions.signInError({ error: errorMessage }));
          })
        )
      )
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signUp),
      exhaustMap(({ email, password, name }) =>
        this.authApiService.signUp({ email, password, displayName: name }).pipe(
          map((user) => authActions.signUpComplete({ user: deepClone(user) })),
          catchError((response) => {
            const errorMessage = this.errorService.parse(response);
            this.alertService.error(errorMessage).subscribe();
            return of(authActions.signUpError({ error: errorMessage }));
          })
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        authActions.signOut,
        authActions.verifyEmailComplete,
        authActions.resetPasswordComplete
      ),
      exhaustMap(() =>
        this.authApiService
          .signOut()
          .pipe(map(() => authActions.signOutComplete()))
      )
    )
  );

  signOutComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.signOutComplete),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.forgotPassword),
      exhaustMap(({ email }) =>
        this.authApiService.sendResetPasswordEmail(email).pipe(
          switchMap(() =>
            this.dialogService.alertDialog({
              header: 'Password Reset',
              body: 'A password reset link has been sent to your email address',
              confirmButton: 'Close',
            })
          ),
          map(() => authActions.forgotPasswordComplete()),
          catchError((response) => {
            const errorMessage = this.errorService.parse(response);
            this.alertService.error(errorMessage).subscribe();
            return of(authActions.forgotPasswordError({ error: errorMessage }));
          })
        )
      )
    )
  );

  initActionPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.initActionPage),
      concatLatestFrom(() => [
        this.store.select(selectQueryParam('mode')),
        this.store.select(selectQueryParam('oobCode')),
      ]),
      map(([, mode, oobCode]) =>
        Boolean(mode) && Boolean(oobCode)
          ? mode === 'resetPassword'
            ? authActions.resetPassword({ oobCode })
            : mode === 'verifyEmail'
            ? authActions.verifyEmail({ oobCode })
            : authActions.initActionPageError()
          : authActions.initActionPageError()
      )
    )
  );

  initActionPageError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.initActionPageError),
        switchMap(() =>
          this.dialogService.alertDialog({
            header: 'Error',
            body: 'There seems to be an error in the link',
            confirmButton: 'HomePage',
          })
        ),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifyEmail),
      switchMap(({ oobCode }) =>
        this.authApiService.verifyEmailOobCode(oobCode).pipe(
          map(() => authActions.verifyEmailComplete()),
          catchError((error) =>
            of(
              authActions.verifyEmailError({
                error: this.errorService.parse(error),
              })
            )
          )
        )
      )
    )
  );

  verifyEmailComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.verifyEmailComplete),
        switchMap(() =>
          this.dialogService.alertDialog({
            header: 'Email Verified',
            body: 'Your email is verified. You will need to login again',
            confirmButton: 'Continue',
          })
        ),
        switchMap(() =>
          this.dialogService.customDialog(loadSignInModalComponent, {
            dismissible: false,
            size: 'm',
          })
        )
      ),
    { dispatch: false }
  );

  verifyEmailError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.verifyEmailError),
        switchMap(({ error }) =>
          this.dialogService.alertDialog({
            header: 'Verification Failed',
            body: `Failed to verify email. ${error}`,
            confirmButton: 'Continue',
          })
        ),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      switchMap(({ oobCode }) =>
        this.dialogService
          .customDialog(loadResetPasswordModalComponent, {
            label: 'Reset Password',
            dismissible: false,
            closeable: false,
            size: 'm',
          })
          .pipe(
            filter(({ password }) => Boolean(password)),
            switchMap(({ password }) =>
              this.authApiService.confirmResetPassword(oobCode, password)
            ),
            map(() => authActions.resetPasswordComplete()),
            catchError((err) =>
              of(authActions.resetPasswordError(this.errorService.parse(err)))
            )
          )
      )
    )
  );

  resetPasswordComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.resetPasswordComplete),
        switchMap(() =>
          this.dialogService.alertDialog({
            header: 'Password Changed',
            body: 'Your password is changed. Please login again',
            confirmButton: 'Continue',
          })
        ),
        switchMap(() =>
          this.dialogService.customDialog(loadSignInModalComponent, {
            dismissible: false,
            size: 'm',
          })
        )
      ),
    { dispatch: false }
  );

  resetPasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.resetPasswordError),
        switchMap(({ error }) =>
          this.dialogService.alertDialog({
            header: 'Password Reset Failed',
            body: `Failed to reset password. ${error}`,
            confirmButton: 'Continue',
          })
        ),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );
}
