import { AppContent } from '../models/app-content.model';

export const APP_INFO: AppContent[] = [
  {
    id: 'about-us',
    title: 'About Us',
    desc: '<p>Incorporated in 2020, Nemozyn is made sustainably in India with non-toxic, organic materials. The certified organic cotton is sourced from Kolkata and our production unit in Delhi makes sure to weave wings for our imagination. The main ethos of our brand is to develop genderless clothing for our youthful and everchanging generation, providing the perfect balance between minimalist workwear and comfortable daily wear.</p>',
  },
  {
    id: 'privacy',
    title: 'Privacy',
    desc: '<p>Any personal information collected through the website is strictly confidential and will only be used to process orders generated by you. Némozyn will not reuse/sell/release your data or send you promotional content based on buyer preferences unless explicitly stated and consented by the user.</p>',
  },
  {
    id: 'delivery-and-exchanges',
    title: 'Delivery & Exchanges',
    desc: `<p><b>Shipping:</b> Standard Delivery in 2-7 working days. All orders are assigned a tracking code after shipment.  </p>
    <p>While orders placed through online payments are eligible for free delivery, we charge a convenience fee of ₹50 for every cash on delivery order.</p>
    <p><b>Cancellations:</b> We accept cancellations until the product has been shipped.</p>

    <p><b>Returns/Exchanges:</b> We inspect the quality of every item before packing. However, in the unlikely event that an item received is damaged or different, we are happy to generate a free return at no cost. Returns are available for a period of 3 days from the day of delivery. To initiate a return, drop us a mail at orders@nemozyn.com with your order no., product name and the reason for return. Please note that we only accept returns in their original packaging.</p>

    <p>Free Exchanges are available in case of a sizing issue. To initiate a Free Exchange, drop us a mail at <a href="mailto:orders@nemozyn.com">orders@nemozyn.com</a> with your order no., product name and the new size. </p>

    <p>We do not accept a return/exchange if: </p>
    <ul class="no-style-type">
      <li>— Items that have been delivered for more than 3 days;</li>
      <li>— The product has been used by the customer;</li>
      <li>— Products that have been damaged/soiled/repaired by the customer;</li>
      <li>— When accessories such as product bag, and tags are damaged, soiled, or lost</li>
    </ul>
    <p><b>Note:</b> Items on sale cannot be returned and are not covered under the "no-questions-asked" return policy. In case of replacement of on sale items, customer needs to bear the charges of sending the product to our warehouse. The replacement shipping is free for all products.</p>
    `,
  },
  {
    id: 'refunds',
    title: 'Refunds',
    desc: '<p>Refunds are initiated within 3 working days. For refund related inquiries, drop us a mail at <a href="mailto:orders@nemozyn.com">orders@nemozyn.com</a></p>',
  },
  {
    id: 'shipping',
    title: 'Shipping',
    desc: `<p>Products are shipped within 24 hours from the time of order. Shipments are made through Delhivery, and can be tracked <a href="http://www.delhivery.com/" target="_blank">here</a>. </p>
    <p>While orders placed through online payments are eligible for free delivery, we charge a convenience fee of ₹50 for every cash on delivery order.</p>
    `,
  },
  {
    id: 'contact-us',
    title: 'Contact Us',
    desc: `
    <p><b>Email: </b> <a href="mailto:orders@nemozyn.com">orders@nemozyn.com</a></p></p>
    <p><b>Address:</b> 43/W/4 East Panpara C Lane, Barrackpore, Kolkata-700123</p>
    <p><b>Phone:</b> +917278177128</p>`,
  },
];
