import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICartItem } from '../models/cart.interface';

@Injectable({
  providedIn: 'root',
})
export class CartApiService {
  constructor(private afs: AngularFirestore) {}

  getItems(uid: string): Observable<ICartItem[]> {
    return this.afs
      .collection('users')
      .doc(uid)
      .collection('user-data')
      .doc('cart')
      .get()
      .pipe(
        map((data) => {
          const items = data.data();
          if (items) {
            return items.data;
          } else {
            return [];
          }
        })
      );
  }

  updateItems(uid: string, items: ICartItem[] = []): Observable<void> {
    return from(
      this.afs
        .collection('users')
        .doc(uid)
        .collection('user-data')
        .doc('cart')
        .update({ data: items })
    );
  }
}
