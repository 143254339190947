import { createReducer, on } from '@ngrx/store';
import { itemActions } from './actions';
import {
  IItemState,
  initialState,
  itemAdapter,
  itemDisplayAdapter,
} from './state';

const reducer = createReducer(
  initialState,
  on(itemActions.loadAllItemsStart, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      all: true,
    },
    allItems: itemDisplayAdapter.removeAll(state.allItems),
  })),
  on(itemActions.loadAllItemsComplete, (state, action) => ({
    ...state,
    loading: {
      ...state.loading,
      all: false,
    },
    ...(action.success
      ? {
          allItems: itemDisplayAdapter.addMany(action.items, state.allItems),
        }
      : {}),
  })),
  on(itemActions.loadItemByIdStart, (state, action) => ({
    ...state,
    loading: {
      ...state.loading,
      item: {
        ...state.loading.item,
        [action.id]: true,
      },
    },
    item: itemAdapter.removeOne(action.id, state.item),
  })),
  on(itemActions.loadItemByIdComplete, (state, action) => ({
    ...state,
    loading: {
      ...state.loading,
      item: {
        ...state.loading.item,
        [action.id]: false,
      },
    },
    ...(action.success
      ? {
          item: itemAdapter.addOne(action.item, state.item),
        }
      : {}),
  })),
  on(itemActions.addItem, (state, action) => ({
    ...state,
    item: itemAdapter.addOne(action.item, state.item),
  })),
  on(itemActions.updateItem, (state, action) => ({
    ...state,
    item: itemAdapter.updateOne(
      {
        id: action.id,
        changes: action.item,
      },
      state.item
    ),
  })),
  on(itemActions.deleteItem, (state, action) => ({
    ...state,
    item: itemAdapter.removeOne(action.id, state.item),
    allItems: itemDisplayAdapter.removeOne(action.id, state.allItems),
  })),
  on(
    itemActions.loadFeaturedItemsStart,
    (state): IItemState => ({
      ...state,
      loading: {
        ...state.loading,
        featured: true,
      },
    })
  ),
  on(
    itemActions.loadFeaturedItemsComplete,
    (state, action): IItemState => ({
      ...state,
      featuredItems: itemDisplayAdapter.addMany(
        action.items,
        state.featuredItems
      ),
      loading: {
        ...state.loading,
        featured: false,
      },
    })
  ),
  on(
    itemActions.loadFeaturedItemsError,
    (state): IItemState => ({
      ...state,
      loading: {
        ...state.loading,
        featured: false,
      },
    })
  )
);

export function ItemsReducer(state: IItemState, action: any) {
  return reducer(state, action);
}
