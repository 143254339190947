import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly user$ = this.afAuth.user;

  readonly isAdmin$ = this.user$.pipe(
    switchMap((user) => (user ? from(user.getIdTokenResult()) : of(null))),
    map((idTokenResult) => Boolean(idTokenResult?.claims?.admin))
  );

  constructor(private afAuth: AngularFireAuth) {}
}
