
  // This file was autogenerated
  export const environment = {
    production: true,
    FIREBASE_API_KEY: 'AIzaSyDt50va7EK6TA9nO3qZk0NGVkyZdo0dh4o',
    firebaseConfig : {
      apiKey: 'AIzaSyDt50va7EK6TA9nO3qZk0NGVkyZdo0dh4o',
      authDomain: 'nemozyn-store.firebaseapp.com',
      databaseURL: 'https://nemozyn-store.firebaseio.com',
      projectId: 'nemozyn-store',
      storageBucket: 'nemozyn-store.appspot.com',
      messagingSenderId: '745408600346',
      appId: '1:745408600346:web:8b4620acbbcbb0835b74a3',
      measurementId: 'G-3G9K0NJZ88'
    },
    razorpayKey : 'rzp_live_dsEdMt3BthK7mP',
    RECAPTCHA_SITE_KEY : '6LeIVY4aAAAAABH4U-M7aM51BpYYV2IiVhU9dlqp',
  };
