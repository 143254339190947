import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TuiButtonModule } from '@taiga-ui/core';
import { loadSignInModalComponent } from 'src/app/modules/auth/modals/sign-in/sign-in-modal-lazy-load.util';
import { CartIconComponent } from 'src/app/modules/cart/components/cart-icon/cart-icon.component';
import { DialogsService } from 'src/app/modules/shared/modules/dialogs/services/dialogs.service';
import { AuthService } from '../../../auth/modules/auth-core/services/auth.service';
import { selectAppUserIsLoggedIn } from '../../store/app.selectors';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden',
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, TuiButtonModule, CartIconComponent],
})
export class AppHeaderComponent {
  readonly isAdmin$ = this.authService.isAdmin$;
  readonly isLoggedIn$ = this.store.select(selectAppUserIsLoggedIn);

  constructor(
    private store: Store,
    private authService: AuthService,
    private dialogService: DialogsService
  ) {}

  openSignInModal(): void {
    this.dialogService
      .customDialog(loadSignInModalComponent, {
        size: 'm',
        dismissible: false,
      })
      .subscribe();
  }
}
