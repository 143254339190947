import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ABOUT } from 'src/app/app-constants/app-about.constants';
import { APP_INFO } from 'src/app/app-constants/app-info.constants';
import { TERMS_CONDITIONS } from 'src/app/app-constants/app-terms-conditions.constants';
import { AppContent } from 'src/app/models/app-content.model';
import { selectAppUserIsLoggedIn } from '../../store/app.selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent {
  abouts: AppContent[] = ABOUT;
  infos: AppContent[] = APP_INFO;
  terms: AppContent[] = TERMS_CONDITIONS;

  isLoggedIn$ = this.store.select(selectAppUserIsLoggedIn);

  constructor(private store: Store) {}
}
