import { BreakpointObserver } from '@angular/cdk/layout';
import { inject, InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const IS_DESKTOP = new InjectionToken<Observable<boolean>>(
  'Is desktop resolution',
  {
    factory: () => {
      const breakpointObserver = inject(BreakpointObserver);

      return breakpointObserver
        .observe('(min-width: 80em)')
        .pipe(map((r) => r.matches));
    },
  }
);

export const IS_DESKTOP_SMALL = new InjectionToken<Observable<boolean>>(
  'Is desktop small resolution',
  {
    factory: () => {
      const breakpointObserver = inject(BreakpointObserver);

      return breakpointObserver
        .observe('(min-width: 64em) and (max-width: 79.9625em)')
        .pipe(map((r) => r.matches));
    },
  }
);

export const IS_TABLET = new InjectionToken<Observable<boolean>>(
  'Is tablet resolution',
  {
    factory: () => {
      const breakpointObserver = inject(BreakpointObserver);

      return breakpointObserver
        .observe('(min-width: 48em) and (max-width: 63.9625em)')
        .pipe(map((r) => r.matches));
    },
  }
);

export const IS_PHONE = new InjectionToken<Observable<boolean>>(
  'Is phone resolution',
  {
    factory: () => {
      const breakpointObserver = inject(BreakpointObserver);

      return breakpointObserver
        .observe('(max-width: 47.9625em)')
        .pipe(map((r) => r.matches));
    },
  }
);
