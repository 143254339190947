import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiBadgedContentModule } from '@taiga-ui/kit';
import { DialogsModule } from 'src/app/modules/shared/modules/dialogs/dialogs.module';
import { DialogsService } from 'src/app/modules/shared/modules/dialogs/services/dialogs.service';
import { loadCartListModalComponent } from '../../modals/cart-list/cart-list-modal-lazy-load.util';
import { CartCoreModule } from '../../modules/cart-core/cart-core.module';
import { cartActions } from '../../modules/cart-core/store/cart.actions';
import { selectCartItemsCount } from '../../modules/cart-core/store/cart.selectors';

@Component({
  selector: 'cart-icon',
  template: `<tui-badged-content [contentTop]="(cartItemsCount$ | async) || ''">
    <ng-container *ngIf="forQuickAccess; else forDesktop">
      <button
        tuiIconButton
        type="button"
        icon="app-shopping-bag"
        appearance="flat"
        size="m"
        (click)="openCart()"
      ></button>
    </ng-container>
    <ng-template #forDesktop>
      <button
        tuiButton
        type="button"
        appearance="primary"
        size="m"
        (click)="openCart()"
      >
        Cart
      </button>
    </ng-template>
  </tui-badged-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DialogsModule,
    CartCoreModule,
    TuiButtonModule,
    TuiBadgedContentModule,
  ],
})
export class CartIconComponent implements OnInit {
  @Input() forQuickAccess: boolean;

  readonly cartItemsCount$ = this.store.select(selectCartItemsCount);

  constructor(private store: Store, private dialogService: DialogsService) {}

  ngOnInit(): void {
    this.store.dispatch(cartActions.load({}));
  }

  openCart(): void {
    this.dialogService
      .customDialog(loadCartListModalComponent, {
        size: 'm',
        closeable: true,
        dismissible: true,
      })
      .subscribe();
  }
}
