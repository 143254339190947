import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectItemsAllItemsEntities } from 'src/app/modules/app-items/modules/products-core/store';
import { ICartItemDisplay } from '../models/cart.interface';
import { cartFeatureKey, cartItemAdapter, ICartState } from './cart.state';

const selectCartFeature = createFeatureSelector<ICartState>(cartFeatureKey);

const cartFeature = createSelector(selectCartFeature, (state) => state.cart);

const { selectAll } = cartItemAdapter.getSelectors(cartFeature);

export const selectCartItems = createSelector(selectAll, (items) => items);

export const selectCartItemsCount = createSelector(
  selectAll,
  (items) => items?.length || 0
);

export const selectCartIsLoading = createSelector(
  selectCartFeature,
  (state) => state.isLoading
);

export const selectCartIsLoaded = createSelector(
  selectCartFeature,
  (state) => state.isLoaded
);

export const selectCartItemsDisplay = createSelector(
  selectCartItems,
  selectItemsAllItemsEntities,
  (cartItems, itemEntities): ICartItemDisplay[] =>
    cartItems.map((item) => ({
      title: itemEntities?.[item.id]?.title || 'N/A',
      id: item.id,
      size: item.size,
      quantity: item.quantity,
      price: itemEntities?.[item.id]?.price,
      isAvailable: Boolean(itemEntities[item.id]),
      total: item.quantity * (itemEntities[item.id]?.price?.retail || 0),
    }))
);

export const selectCartItemsTotal = createSelector(
  selectCartItemsDisplay,
  (items) =>
    items.reduce((acc, item) => {
      return (acc += item.quantity * (item.price?.retail || 0));
    }, 0)
);
