<div class="footer-container app-page-container">
  <ul class="quick-links">
    <li [routerLink]="['/info/' + info.id]" *ngFor="let info of infos">
      {{ info.title }}
    </li>
    <li [routerLink]="['/terms-and-conditions']">Terms and Conditions</li>
    <li>
      <a href="https://www.instagram.com/nemozyn/" target="_blank">
        Instagram
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/nemozyn/" target="_blank"> Facebook </a>
    </li>
  </ul>
  <div class="display-logo">
    <app-logo></app-logo>
  </div>
</div>
<!-- <div class="app-page-container footer-bottom">
  <div class="copyright">&copy; NÉMOZYN 2022</div>
</div> -->
