import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ICartItem } from '../models/cart.interface';

export interface ICartState {
  cart: EntityState<ICartItem>;
  isLoading: boolean;
  isLoaded: boolean;
}

export const cartItemAdapter = createEntityAdapter<ICartItem>({
  selectId: ({ id, size }) => `${id}_${size}`,
});

export const cartInitialState: ICartState = {
  cart: cartItemAdapter.getInitialState(),
  isLoading: false,
  isLoaded: false,
};

export const cartFeatureKey = 'Cart';
