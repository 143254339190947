import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ICartItem } from '../models/cart.interface';
import { cartFeatureKey } from './cart.state';

export const cartActions = createActionGroup({
  source: cartFeatureKey,
  events: {
    'Clear State': emptyProps(),
    Load: props<{ force?: boolean; allItems?: boolean }>(),
    'Load Start': emptyProps(),
    'Load Success': props<{ items: ICartItem[] }>(),
    'Load Error': emptyProps(),
    Remove: props<{ item: ICartItem }>(),
    'Remove All': emptyProps(),
    Add: props<{ item: ICartItem }>(),
    'Change Quantity': props<{ id: string; size: string; quantity: number }>(),
    Update: emptyProps(),
    'Update Complete': emptyProps(),
  },
});
