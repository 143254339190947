import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthApiService } from './services/auth-api.service';
import { AuthEffects } from './store/auth.effects';
import { AuthFeatureKey } from './store/auth.feature-key';
import { AuthReducer } from './store/auth.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(AuthFeatureKey, AuthReducer),
    EffectsModule.forFeature([AuthEffects]),
    AngularFireAuthModule,
  ],
  providers: [AuthApiService],
})
export class AuthCoreModule {}
