import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import { appFeatureKey } from './app-feature-key';
import { IUserState } from './app.state';

export const appActions = createActionGroup({
  source: appFeatureKey,
  events: {
    'Show Loader': props<{ transparent?: boolean }>(),
    'Hide Loader': emptyProps(),
    'Load Image Url': props<{ path: string }>(),
    'Load Image Url Start': props<{ path: string }>(),
    'Load Image Url Complete': props<{ path: string; url: string }>(),
    'Load Image Url Error': props<{ path: string }>(),
    Init: emptyProps(),
    'Init Complete': props<{ user?: IUserState }>(),
    'Set User': props<{ user: IUserState }>(),
    'Clear State': emptyProps(),
    'Noop Action': emptyProps(),
  },
});
