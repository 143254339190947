import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ItemsApiService } from './services/items-api.service';
import { ItemsService } from './services/items.service';
import { AppItemsEffects, featureKey, ItemsReducer } from './store';

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, ItemsReducer),
    EffectsModule.forFeature([AppItemsEffects]),
  ],
  providers: [ItemsService, ItemsApiService],
})
export class ProductsCoreModule {}
