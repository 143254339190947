import { Injectable } from "@angular/core";

@Injectable()
export class AppCacheService {
  private cache = 'appCache'

  constructor() {
  }

  /**
   * Stores any data to session storage
   * @param {string} key    unique key of the data to be stored
   * @param {any} value     data to be stored
   * @param {Number} expirySeconds expiry of the data (in seconds) (defaults to no expiry)
   */
  addSessionCache(key, value, expirySeconds) {
    const expiry = expirySeconds ? (Date.now() + (expirySeconds * 1000)) : null; // calculate the expiry time
    let cachedItems = this.fetchAllSessionCache();
    if(!cachedItems) {
      cachedItems = {};
    }
    cachedItems[key] = {
      content : value,
      expiry
    };
    this.updateAllSessionCache(cachedItems);
  }

  /**
   * Returns the data stored in the session storage for the provided key
   * @param {string} key unique key of the data to be fetched
   */
  getSessionCache(key) {
    try {
      let cachedItems = this.fetchAllSessionCache();
      let data = cachedItems && cachedItems[key] ? cachedItems[key] : null;
      if(data && data.content) {
        if(this.isExpired(data.expiry)) {
          delete cachedItems[key];
          this.updateAllSessionCache(cachedItems);
          return null;
        }
        else {
          return data.content;
        }
      }
      else {
        return null;
      }
    }
    catch(err) {

      return null;
    }
  }

  /**
   * Returns all the data stored in the session storage for app Cache
   */
  fetchAllSessionCache() {
    return sessionStorage.getItem(this.cache) ? this.decodeData(sessionStorage.getItem(this.cache)) : {};
  }

  /**
   * Stores all the updated cache data
   * @param {object} data key:value paur containing the cache items
   */
  updateAllSessionCache(data) {
    sessionStorage.setItem(this.cache, this.encodeData(data));
  }

  /**
   * Deletes all session storage cached items for app cache
   */
  deleteAllSessionCache() {
    sessionStorage.removeItem(this.cache);
  }

  /**
   * Stores any data to local storage
   * @param {string} key    unique key of the data to be stored
   * @param {any} value     data to be stored
   * @param {Number} expirySeconds expiry of the data (in seconds) (defaults to no expiry)
   */
  addLocalCache(key, value, expirySeconds) {
    const expiry = expirySeconds ? (Date.now() + (expirySeconds * 1000)) : null; // calculate the expiry time
    let cachedItems = this.fetchAllLocalCache();
    if(!cachedItems) {
      cachedItems = {};
    }
    cachedItems[key] = {
      content : value,
      expiry
    };
    this.updateAllLocalCache(cachedItems);
  }

  /**
   * Returns the data stored in the local storage for the provided key
   * @param {string} key unique key of the data to be fetched
   */
  getLocalCache(key) {
    try {
      let cachedItems = this.fetchAllLocalCache();
      let data = cachedItems && cachedItems[key] ? cachedItems[key] : null;
      if(data && data.content) {
        if(this.isExpired(data.expiry)) {
          delete cachedItems[key];
          this.updateAllLocalCache(cachedItems);
          return null;
        }
        else {
          return data.content;
        }
      }
      else {
        return null;
      }
    }
    catch(err) {

      return null;
    }
  }

  /**
   * Returns all the data stored in the local storage for app Cache
   */
  fetchAllLocalCache() {
    return localStorage.getItem(this.cache) ? this.decodeData(localStorage.getItem(this.cache)) : {};
  }

  /**
   * Stores all the updated cache data
   * @param {object} data key:value paur containing the cache items
   */
  updateAllLocalCache(data) {
    localStorage.setItem(this.cache, this.encodeData(data));
  }

  /**
   * Deletes all local storage cached items for app cache
   */
  deleteAllLocalCache() {
    localStorage.removeItem(this.cache);
  }

  /**
   * Returns if the data is expired
   * @param {number} time
   */
  isExpired(time) {
    if(!time || time >= Date.now()) {
      return false;
    }
    else {
      return true;
    }
  }

  /**
   * Decodes base 64 string to stored data
   * @param {string} data data to be decoded
   */
  decodeData(data) {
    try {
      return JSON.parse(atob(data));
    }
    catch(err) {
      return null;
    }
  }

  /**
   * Encodes any data into base64 format
   * @param {any} data data to be encoded
   */
  encodeData(data) {
    try {
      return btoa(JSON.stringify(data));
    }
    catch(err) {
      return null;
    }
  }
}
