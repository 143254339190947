import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppPageLoaderService {
  private loaderSubject = new BehaviorSubject<boolean>(false);
  private transparentSubject = new BehaviorSubject<boolean>(false);

  readonly canShowLoader$ = this.loaderSubject.asObservable();
  readonly isTransparent$ = this.transparentSubject.asObservable();

  showLoader(isTransparent = false): void {
    this.loaderSubject.next(true);
    this.transparentSubject.next(isTransparent);
  }

  hideLoader(): void {
    this.loaderSubject.next(false);
  }
}
