import { inject, InjectionToken } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { getGlobalActivatedRoute } from '../utils/global-activated-route';

export const QUICK_ACCESS_HIDDEN = new InjectionToken<Observable<boolean>>(
  'quickAccessHidden',
  {
    factory: () => {
      const router = inject(Router);
      const activatedRoute = inject(ActivatedRoute);

      return router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(router),
        map(() => getGlobalActivatedRoute(activatedRoute)),
        switchMap((route) => route.data),
        map((data) => !!data.hideQuickAccess),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    },
  }
);
