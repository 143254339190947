import { Action, createReducer, on } from '@ngrx/store';
import { authActions } from './auth.actions';
import { authInitialState, IAuthState } from './auth.state';

const reducer = createReducer(
  authInitialState,
  on(
    authActions.signIn,
    authActions.signUp,
    authActions.forgotPassword,
    authActions.resetPassword,
    authActions.verifyEmail,
    (state): IAuthState => ({
      ...state,
      isLoading: true,
      error: null,
    })
  ),
  on(
    authActions.signInComplete,
    authActions.signUpComplete,
    authActions.forgotPasswordComplete,
    authActions.verifyEmailComplete,
    authActions.resetPasswordComplete,
    (state): IAuthState => ({
      ...state,
      isLoading: false,
      error: null,
    })
  ),
  on(
    authActions.signInError,
    authActions.signUpError,
    authActions.forgotPasswordError,
    authActions.verifyEmailError,
    authActions.resetPasswordError,
    (state, action): IAuthState => ({
      ...state,
      isLoading: false,
      error: action.error,
    })
  )
);

export function AuthReducer(state: IAuthState, action: Action): IAuthState {
  return reducer(state, action);
}
