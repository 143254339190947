import firebase from 'firebase/compat';

export interface IUserState
  extends Pick<
    firebase.User,
    | 'displayName'
    | 'email'
    | 'emailVerified'
    | 'phoneNumber'
    | 'photoURL'
    | 'uid'
    | 'isAnonymous'
    | 'metadata'
  > {}

export interface IAppState {
  hasInit: boolean;
  images: Record<string, string>; // path : url
  imagesLoading: Record<string, boolean>;
  user: IUserState;
}

export const initialState: IAppState = {
  hasInit: false,
  images: {},
  imagesLoading: {},
  user: null,
};
