import { createFeatureSelector, createSelector } from '@ngrx/store';
import { routerStateConfig } from './router.module';
import { MergedRouteReducerState } from './router.state';

export const selectRouterReducerState =
  createFeatureSelector<MergedRouteReducerState>(routerStateConfig.stateKey);

// export const getMergedRoute = createSelector(
//   getRouterReducerState,
//   (routerReducerState) => routerReducerState.state
// );

export const selectRouteParams = createSelector(
  selectRouterReducerState,
  (routerState) => routerState.state.params
);

export const selectRouteParam = (param: string) =>
  createSelector(selectRouteParams, (params) => params?.[param]);

export const selectQueryParams = createSelector(
  selectRouterReducerState,
  (routerState) => routerState.state.queryParams
);

export const selectQueryParam = (param: string) =>
  createSelector(selectQueryParams, (params) => params?.[param]);
