import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from './app.state';

const selectAppState = createFeatureSelector<IAppState>('app');

export const selectAppUser = createSelector(
  selectAppState,
  (state) => state.user
);

export const selectAppUserUid = createSelector(
  selectAppUser,
  (user) => user?.uid
);

export const selectAppUserIsLoggedIn = createSelector(
  selectAppUser,
  (user) => user?.uid && !user?.isAnonymous
);

export const selectAppHasInit = createSelector(
  selectAppState,
  (state) => state.hasInit
);

export const selectAppImageUrlByPath = (path: string) =>
  createSelector(selectAppState, (state) => state.images[path]);

export const selectAppImageLoadingByPath = (path: string) =>
  createSelector(selectAppState, (state) => state.imagesLoading[path] || false);
