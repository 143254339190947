import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { loadSignInModalComponent } from 'src/app/modules/auth/modals/sign-in/sign-in-modal-lazy-load.util';
import { CartIconComponent } from 'src/app/modules/cart/components/cart-icon/cart-icon.component';
import { DialogsService } from 'src/app/modules/shared/modules/dialogs/services/dialogs.service';
import { selectAppUserIsLoggedIn } from '../../store/app.selectors';

@Component({
  selector: 'quick-access-bar',
  templateUrl: './quick-access-bar.component.html',
  styleUrls: ['./quick-access-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    RouterModule,
    CartIconComponent,
    TuiSvgModule,
  ],
})
export class QuickAccessBarComponent {
  readonly isLoggedIn$ = this.store.select(selectAppUserIsLoggedIn);

  constructor(private store: Store, private dialogService: DialogsService) {}

  openSignInModal(): void {
    this.dialogService
      .customDialog(loadSignInModalComponent, {
        size: 'm',
        dismissible: false,
      })
      .subscribe();
  }
}
