import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StorageService } from 'src/app/modules/app-core/services/storage.service';
import { ICartItem } from '../models/cart.interface';
import { cartFeatureKey } from '../store/cart.state';

@Injectable({ providedIn: 'root' })
export class CartStorageService {
  private readonly KEY = cartFeatureKey;

  constructor(private storageService: StorageService) {}

  getItems(): Observable<ICartItem[]> {
    return of(this.storageService.getData(this.KEY) || []);
  }

  updateItems(items: ICartItem[] = []): Observable<void> {
    return of(this.storageService.setData(this.KEY, items));
  }
}
