import { Action, createReducer, on } from '@ngrx/store';
import { cartActions } from './cart.actions';
import { cartInitialState, cartItemAdapter, ICartState } from './cart.state';

const cartReducer = createReducer(
  cartInitialState,
  on(cartActions.clearState, (): ICartState => cartInitialState),
  on(
    cartActions.loadStart,
    (state): ICartState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    cartActions.loadSuccess,
    (state, action): ICartState => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      cart: cartItemAdapter.setAll(action.items, state.cart),
    })
  ),
  on(
    cartActions.loadError,
    (state): ICartState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    cartActions.add,
    (state, action): ICartState => ({
      ...state,
      cart: cartItemAdapter.addOne(action.item, state.cart),
    })
  ),
  on(
    cartActions.remove,
    (state, action): ICartState => ({
      ...state,
      cart: cartItemAdapter.removeOne(
        `${action.item.id}_${action.item.size}`,
        state.cart
      ),
    })
  ),
  on(
    cartActions.removeAll,
    (state): ICartState => ({
      ...state,
      cart: cartItemAdapter.removeAll(state.cart),
    })
  ),
  on(
    cartActions.changeQuantity,
    (state, action): ICartState => ({
      ...state,
      cart: cartItemAdapter.updateOne(
        {
          id: `${action.id}_${action.size}`,
          changes: {
            quantity: action.quantity,
          },
        },
        state.cart
      ),
    })
  ),
  on(
    cartActions.update,
    (state): ICartState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    cartActions.updateComplete,
    (state): ICartState => ({
      ...state,
      isLoading: false,
    })
  )
);

export function CartReducer(state: ICartState, action: Action): ICartState {
  return cartReducer(state, action);
}
