import { Injectable } from '@angular/core';
import { ERROR } from '../constants/app-errors.constants';

@Injectable({
  providedIn: 'root',
})
export class AppErrorService {
  constructor() {}

  parse(error) {
    if (typeof error == 'object') {
      if (error.hasOwnProperty('code') && ERROR[error.code]) {
        return ERROR[error.code];
      } else if (error.hasOwnProperty('message')) {
        return error.message;
      } else {
        return error.toString();
      }
    } else if (typeof error == 'string') {
      return error;
    } else {
      return error.toString();
    }
  }
}
