import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  NgModule,
} from '@angular/core';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TuiRootModule, TUI_SANITIZER } from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import * as Hammer from 'hammerjs';
// import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCoreModule } from './modules/app-core/app-core.module';
import { AppCacheService } from './services/app-cache.service';
import { WindowRefService } from './services/window-ref.service';

import { filter, take } from 'rxjs/operators';
import { appActions } from './modules/app-core/store/app.actions';
import { AppEffects } from './modules/app-core/store/app.effects';
import { AppReducer } from './modules/app-core/store/app.reducers';
import { selectAppHasInit } from './modules/app-core/store/app.selectors';
import { NgrxRouterStoreModule } from './modules/app-core/store/router/router.module';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [AppComponent, routingComponents],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    StoreModule.forRoot({ app: AppReducer }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      name: 'Ngrx Debugging',
      maxAge: 25,
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    AppCoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // RecaptchaV3Module,
    HttpClientModule,
    NgrxRouterStoreModule,
    TuiRootModule,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.RECAPTCHA_SITE_KEY,
    // },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.production
        ? undefined
        : ['http://localhost:9099/', 9099],
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 9000],
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 8089],
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 5001],
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 9199],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [Store],
      multi: true,
    },
    WindowRefService,
    AppCacheService,
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}

export function initApp(store: Store) {
  return () => {
    store.dispatch(appActions.init());
    return store.select(selectAppHasInit).pipe(
      filter((init) => init),
      take(1)
    );
  };
}
