import { Inject, Injectable, Injector, Type } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { defer, Observable } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { loadAlertDialogComponent } from '../components/alert-dialog/alert-dialog-lazy-load.util';
import { loadConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog-lazy-load.util';
import { IAlertDialogOptions } from '../models/alert-dialog.interfaces';
import { IConfirmDialogOptions } from '../models/confirm-dialog.interface';
import { ICustomDialogOptions } from '../models/custom-dialog.interface';

@Injectable()
export class DialogsService {
  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector
  ) {}

  alertDialog(options: IAlertDialogOptions): Observable<void> {
    return defer(() => loadAlertDialogComponent()).pipe(
      exhaustMap((component) =>
        this.dialogService.open<void>(
          new PolymorpheusComponent(component, this.injector),
          {
            data: options,
            label: options.header,
            dismissible: options.dismissible ?? false,
            closeable: options.closeable ?? false,
            size: options.size,
          }
        )
      )
    );
  }

  confirmDialog(options: IConfirmDialogOptions): Observable<boolean> {
    return defer(() => loadConfirmDialogComponent()).pipe(
      exhaustMap((component) =>
        this.dialogService.open<boolean>(
          new PolymorpheusComponent(component, this.injector),
          {
            data: options,
            label: options.header,
            dismissible: options.dismissible ?? false,
            closeable: options.closeable ?? false,
            size: options.size,
          }
        )
      )
    );
  }

  customDialog(
    component: () => Promise<Type<any>>,
    options: ICustomDialogOptions
  ): Observable<unknown> {
    return defer(() => component()).pipe(
      exhaustMap((com: Type<any>) =>
        this.dialogService.open<unknown>(
          new PolymorpheusComponent(com, this.injector),
          {
            data: options.data,
            dismissible: options.dismissible ?? false,
            label: options.label,
            closeable: options.closeable ?? true,
            size: options.size,
          }
        )
      )
    );
  }
}
