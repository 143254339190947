import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICartItem } from '../models/cart.interface';
import { CartApiService } from './cart-api.service';

@Injectable()
export class CartService {
  constructor(private cartApiService: CartApiService) {}

  getItems(uid: string): Observable<ICartItem[]> {
    return this.cartApiService.getItems(uid);
  }

  updateItems(uid: string, items: ICartItem[] = []): Observable<void> {
    return this.cartApiService.updateItems(uid, items);
  }
}
