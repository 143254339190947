import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IItem, IItemDisplay } from '../interfaces/app-item.model';
import { ItemsApiService } from './items-api.service';

@Injectable({ providedIn: 'root' })
export class ItemsService {
  constructor(private itemsApiService: ItemsApiService) {}

  getAllItems(): Observable<IItemDisplay[]> {
    return this.itemsApiService.getAllItems().pipe(
      map((data) =>
        Object.keys(data).map((id) => ({
          id,
          ...data[id],
        }))
      )
    );
  }

  getFeaturedItems(): Observable<IItemDisplay[]> {
    return this.itemsApiService.getFeaturedItems().pipe(
      map((data) =>
        Object.keys(data).map((id) => ({
          id,
          ...data[id],
        }))
      )
    );
  }

  getItem(id: string): Observable<IItem> {
    return this.itemsApiService.getItem(id).pipe(
      map((item) => {
        if (Boolean(item)) {
          return { ...item, id };
        } else {
          return null;
        }
      })
    );
  }
}
