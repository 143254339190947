import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { authActions } from 'src/app/modules/auth/modules/auth-core/store/auth.actions';
import { AuthService } from '../../auth/modules/auth-core/services/auth.service';
import { appActions } from './app.actions';
import {
  selectAppImageLoadingByPath,
  selectAppImageUrlByPath,
} from './app.selectors';
@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private afStorage: AngularFireStorage
  ) {}

  clearState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signOutComplete),
      map(() => appActions.clearState())
    )
  );

  loadImageUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.loadImageUrl),
      concatLatestFrom(({ path }) => [
        this.store.select(selectAppImageUrlByPath(path)),
        this.store.select(selectAppImageLoadingByPath(path)),
      ]),
      filter(
        ([{ path }, url, isLoading]) =>
          Boolean(path) && !Boolean(url) && !isLoading
      ),
      map(([{ path }]) => appActions.loadImageUrlStart({ path }))
    )
  );

  loadImageUrlStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.loadImageUrlStart),
      mergeMap(({ path }) =>
        defer(() =>
          this.afStorage.storage.app
            .storage()
            .ref()
            .child(path)
            .getDownloadURL()
            .catch((err) => null)
        ).pipe(
          map((url) => appActions.loadImageUrlComplete({ path, url })),
          catchError(() => of(appActions.loadImageUrlError({ path })))
        )
      )
    )
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.init),
      switchMap(() => this.authService.user$),
      map((user) => {
        return appActions.initComplete({
          user: user
            ? {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                metadata: user.metadata,
                isAnonymous: user.isAnonymous,
              }
            : null,
        });
      })
    )
  );

  initComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.initComplete),
      filter(({ user }) => Boolean(user)),
      map(({ user }) => appActions.setUser({ user }))
    )
  );
}
