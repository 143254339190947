<span
  *ngFor="let item of breadcrumbs; let last = last"
  [ngClass]="last ? 'active' : ''"
>
  <a class="app-link" [routerLink]="item.path" *ngIf="item.path">{{
    item.title
  }}</a>
  <span *ngIf="!item.path">{{ item.title }}</span>
  <span class="divider" *ngIf="!last">&gt;</span>
</span>
