import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  style,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  Self,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import { NgOnDestroyService } from '../../shared/services/ng-on-destroy.service';
enum Direction {
  Up = 'Up',
  Down = 'Down',
}

/** ToDo: Refactor */
/** @dynamic */
@Directive({
  selector: '[stickyHeader]',
  providers: [NgOnDestroyService],
})
export class StickyHeaderDirective implements AfterViewInit, OnDestroy {
  player: AnimationPlayer;

  window: Window;

  set show(show: boolean) {
    if (this.player) {
      this.player.destroy();
    }

    const metadata = show ? this.fadeIn() : this.fadeOut();

    const factory = this.builder.build(metadata);
    const player = factory.create(this.el.nativeElement);

    player.play();
  }

  constructor(
    private builder: AnimationBuilder,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    @Self() private ngOnDestroy$: NgOnDestroyService
  ) {
    this.window = this.document.defaultView;
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0, top: '-80px' }),
      animate('400ms ease-in', style({ opacity: 1, top: '0px' })),
    ];
  }

  private fadeOut(): AnimationMetadata[] {
    return [
      style({ opacity: '*', top: '0px' }),
      animate('400ms ease-in', style({ opacity: 0, top: '-80px' })),
    ];
  }

  private fadeUp(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate(
        '200ms ease-in',
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
    ];
  }

  private fadeDown(): AnimationMetadata[] {
    return [
      style({ opacity: '*' }),
      animate(
        '200ms ease-in',
        style({ opacity: 0, transform: 'translateY(-100%)' })
      ),
    ];
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(this.window, 'scroll').pipe(
      throttleTime(10),
      map(() => this.window.scrollY),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share(),
      takeUntil(this.ngOnDestroy$)
    );

    const goingUp$ = scroll$.pipe(
      filter((direction) => direction === Direction.Up)
    );

    const goingDown$ = scroll$.pipe(
      filter((direction) => direction === Direction.Down)
    );

    goingUp$.subscribe(() => (this.show = true));
    goingDown$.subscribe(() => (this.show = false));

    this.show = true;
  }

  ngOnDestroy() {}
}
