import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { UserGuard } from './guards/user.guard';
import { LayoutDefaultComponent } from './modules/app-core/layouts/layout-default/layout-default.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((mod) => mod.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: '',
    component: LayoutDefaultComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((mod) => mod.HomeModule),
      },
      {
        path: 'item',
        loadChildren: () =>
          import('./modules/app-items/app-items.module').then(
            (mod) => mod.AppItemsModule
          ),
      },
      {
        path: 'account',
        canActivate: [UserGuard],
        loadChildren: () =>
          import('./modules/user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'checkout',
        // ToDo: Add checkout guard and resolvers
        // canActivate: [CheckoutGuard],
        // canActivateChild: [CheckoutGuard],
        loadChildren: () =>
          import('./modules/checkout/checkout.module').then(
            (mod) => mod.CheckoutModule
          ),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/content-page/content-page.module').then(
            (mod) => mod.ContentPageModule
          ),
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [];
