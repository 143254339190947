<ng-container
  *ngIf="{
    isPhone: isPhone$ | async,
    showHeader: !(headerHidden$ | async),
    showQuickAccess: !(quickAccessHidden$ | async)
  } as d"
>
  <app-header *ngIf="!d.isPhone && d.showHeader" [stickyHeader]></app-header>
  <div
    class="router-outlet"
    [ngClass]="{
      'router-outlet__with-quick-access': d.showQuickAccess && d.isPhone,
      'router-outlet__with-header': d.showHeader && !d.isPhone
    }"
  >
    <router-outlet></router-outlet>
    <app-footer *ngIf="!(footerHidden$ | async) && !d.isPhone"></app-footer>
  </div>
  <quick-access-bar *ngIf="d.showQuickAccess && d.isPhone"></quick-access-bar>
</ng-container>
