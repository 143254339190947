import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { selectAppUserIsLoggedIn } from '../modules/app-core/store/app.selectors';

@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(selectAppUserIsLoggedIn).pipe(
      take(1),
      map((isLoggedIn) => (isLoggedIn ? true : this.router.createUrlTree([''])))
    );
  }
}
