import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat';
import { defer, forkJoin, Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ISignUp } from '../interfaces/sign-up.interface';

@Injectable()
export class AuthApiService {
  constructor(private afAuth: AngularFireAuth) {}

  signIn(
    email: string,
    password: string
  ): Observable<firebase.auth.UserCredential> {
    return defer(() => this.afAuth.signInWithEmailAndPassword(email, password));
  }

  signOut(): Observable<void> {
    return defer(() => this.afAuth.signOut());
  }

  signUp(user: ISignUp): Observable<firebase.auth.UserCredential> {
    return defer(() =>
      this.afAuth.createUserWithEmailAndPassword(user.email, user.password)
    ).pipe(
      switchMap((response) =>
        forkJoin([
          defer(() =>
            response.user.updateProfile({
              displayName: user.displayName,
            })
          ),
          defer(() => response.user.sendEmailVerification()),
        ]).pipe(map(() => response))
      )
    );
  }

  sendResetPasswordEmail(email: string): Observable<void> {
    return defer(() => this.afAuth.sendPasswordResetEmail(email));
  }

  verifyEmailOobCode(code: string): Observable<void> {
    return defer(() => this.afAuth.checkActionCode(code)).pipe(
      switchMap((result) => {
        if (result.operation !== 'VERIFY_EMAIL') {
          throwError('Invalid Operation');
        } else {
          return defer(() => this.afAuth.applyActionCode(code));
        }
      })
    );
  }

  confirmResetPassword(oobCode: string, password: string): Observable<void> {
    return defer(() => this.afAuth.confirmPasswordReset(oobCode, password));
  }

  googleSignin(): Observable<firebase.auth.UserCredential> {
    const provider = new GoogleAuthProvider();
    return defer(() => this.afAuth.signInWithPopup(provider));
  }
}
