import { Inject, Injectable } from '@angular/core';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { Observable } from 'rxjs';

@Injectable()
export class AlertsService {
  constructor(
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService
  ) {}

  success(message: string, heading?: string): Observable<void> {
    return this.alertService.open(message, {
      status: TuiNotification.Success,
      autoClose: 5000,
      hasCloseButton: true,
      hasIcon: true,
      label: heading || '',
    });
  }

  error(message: string, heading?: string): Observable<void> {
    return this.alertService.open(message, {
      status: TuiNotification.Error,
      autoClose: 5000,
      hasCloseButton: true,
      hasIcon: true,
      label: heading || '',
    });
  }

  info(message: string, heading?: string): Observable<void> {
    return this.alertService.open(message, {
      status: TuiNotification.Info,
      autoClose: 10000,
      hasCloseButton: true,
      hasIcon: true,
      label: heading || '',
    });
  }

  warning(message: string, heading?: string): Observable<void> {
    return this.alertService.open(message, {
      status: TuiNotification.Warning,
      autoClose: 1500,
      hasCloseButton: true,
      hasIcon: true,
      label: heading || '',
    });
  }
}
