export const ERROR = {
  'auth/expired-action-code': 'The link has expired. Please try again.',
  'auth/invalid-action-code': 'The link is invalid. Please try again.',
  'auth/user-disabled': 'Sorry, the user is deactivated',
  'auth/user-not-found': 'Incorrect Username or Password',
  'auth/weak-password':
    'The password is weak. Please try again with a strong password',
  'auth/invalid-email': 'Email address is invalid',
  'auth/wrong-password': 'Incorrect Username or Password',
  'auth/email-already-in-use':
    "The email address already exists. Click on 'Log In' to continue with the account",
  'auth/provider-already-linked': 'You already have the phone number linked',
  'auth/invalid-phone-number': 'Phone number is invalid',
  'auth/invalid-verification-code': 'Invalid OTP',
};
