import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Self,
} from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NgOnDestroyService } from 'src/app/modules/shared/services/ng-on-destroy.service';
import {
  IS_PHONE,
  IS_TABLET,
} from 'src/app/modules/shared/tokens/device-resolution.tokens';
import { FOOTER_HIDDEN } from '../../tokens/footer-hidden.token';
import { HEADER_HIDDEN } from '../../tokens/header-hidden.token';
import { HEADER_NAV_HIDDEN } from '../../tokens/header-nav-hidden.token';
import { QUICK_ACCESS_HIDDEN } from '../../tokens/quick-access-bar-hidden.token';

@Component({
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class LayoutDefaultComponent implements OnInit {
  constructor(
    @Inject(HEADER_HIDDEN) readonly headerHidden$: Observable<boolean>,
    @Inject(FOOTER_HIDDEN) readonly footerHidden$: Observable<boolean>,
    @Inject(QUICK_ACCESS_HIDDEN)
    readonly quickAccessHidden$: Observable<boolean>,
    @Inject(HEADER_NAV_HIDDEN) readonly headerNavHidden$: Observable<boolean>,
    @Self() private ngOnDestroy$: NgOnDestroyService,
    @Inject(IS_PHONE) public isPhone$: Observable<boolean>,
    @Inject(IS_TABLET) public isTablet$: Observable<boolean>,
    private router: Router
  ) {}

  ngOnInit(): void {
    // ToDo: Find better solution for reloading current page
    //Reload Layout
    combineLatest([this.isPhone$, this.isTablet$])
      .pipe(
        takeUntil(this.ngOnDestroy$),
        distinctUntilChanged(
          (phone, tablet) => phone[0] === phone[1] && tablet[0] === tablet[1]
        )
      )
      .subscribe(() => {
        const currentUrl = this.router.url;
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          });
      });
  }
}
