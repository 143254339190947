import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { selectRouteParam } from 'src/app/modules/app-core/store/router/router.selectors';
import { AlertsService } from 'src/app/modules/shared/modules/alerts/services/alerts.service';
import { ItemsService } from '../services/items.service';
import { itemActions } from './actions';
import {
  selectAllItems,
  selectIsAllItemsLoading,
  selectIsItemLoading,
  selectItemDetailsById,
  selectItemsFeaturedItems,
  selectItemsIsFeaturedItemsLoading,
} from './selectors';
import { IItemState } from './state';

@Injectable()
export class AppItemsEffects {
  constructor(
    private action$: Actions,
    private store: Store<IItemState>,
    private itemsService: ItemsService,
    private alertService: AlertsService
  ) {}

  loadCurrentItem$ = createEffect(() => {
    return this.action$.pipe(
      ofType(itemActions.loadCurrentItem),
      concatLatestFrom(() => this.store.select(selectRouteParam('id'))),
      map(([, id]) => itemActions.loadItemById({ id }))
    );
  });

  loadAllItems$ = createEffect(() => {
    return this.action$.pipe(
      ofType(itemActions.loadAllItems),
      concatLatestFrom(() => [
        this.store.select(selectIsAllItemsLoading),
        this.store.select(selectAllItems),
      ]),
      filter(
        ([{ force }, isLoading, allItems]) =>
          !isLoading && (force || !allItems.length)
      ),
      map(() => itemActions.loadAllItemsStart())
    );
  });

  loadAllItemsStart$ = createEffect(() => {
    return this.action$.pipe(
      ofType(itemActions.loadAllItemsStart),
      switchMap(() =>
        this.itemsService.getAllItems().pipe(
          map((items) =>
            itemActions.loadAllItemsComplete({ items, success: true })
          ),
          catchError(() => {
            this.alertService.error('Failed to load items').subscribe();
            return of(
              itemActions.loadAllItemsComplete({ items: [], success: false })
            );
          })
        )
      )
    );
  });

  loadItemById$ = createEffect(() =>
    this.action$.pipe(
      ofType(itemActions.loadItemById),
      concatLatestFrom(({ id, force }) => [
        this.store.select(selectItemDetailsById(id)),
        this.store.select(selectIsItemLoading(id)),
      ]),
      filter(
        ([{ id, force }, itemDetails, isLoading]) =>
          Boolean(id) && !isLoading && (force || !itemDetails)
      ),
      map(([{ id }]) => itemActions.loadItemByIdStart({ id }))
    )
  );

  loadItemByIdStart$ = createEffect(() =>
    this.action$.pipe(
      ofType(itemActions.loadItemByIdStart),
      switchMap(({ id }) =>
        this.itemsService.getItem(id).pipe(
          map((item) =>
            itemActions.loadItemByIdComplete({ id, item, success: true })
          ),
          catchError((error) => {
            this.alertService.error('Failed to load Item').subscribe();
            return of(
              itemActions.loadItemByIdComplete({
                id,
                item: null,
                success: false,
              })
            );
          })
        )
      )
    )
  );

  loadFeaturedItems$ = createEffect(() => {
    return this.action$.pipe(
      ofType(itemActions.loadFeaturedItems),
      concatLatestFrom(() => [
        this.store.select(selectItemsFeaturedItems),
        this.store.select(selectItemsIsFeaturedItemsLoading),
      ]),
      filter(
        ([{ force }, items, isLoading]) =>
          !isLoading && (force || !items.length)
      ),
      map(() => itemActions.loadFeaturedItemsStart())
    );
  });

  loadFeaturedItemsStart$ = createEffect(() => {
    return this.action$.pipe(
      ofType(itemActions.loadFeaturedItemsStart),
      switchMap(() =>
        this.itemsService.getFeaturedItems().pipe(
          map((items) => itemActions.loadFeaturedItemsComplete({ items })),
          catchError(() => of(itemActions.loadFeaturedItemsError()))
        )
      )
    );
  });
}
