import { createActionGroup, emptyProps, props } from '@ngrx/store';
import firebase from 'firebase/compat';
import { AuthFeatureKey } from './auth.feature-key';

export const authActions = createActionGroup({
  source: AuthFeatureKey,
  events: {
    'Sign In': props<{ email: string; password: string }>(),
    'Sign In Complete': props<{ user: firebase.auth.UserCredential }>(),
    'Sign In Error': props<{ error: string }>(),
    'Sign In With Google': emptyProps(),
    'Sign Up': props<{ email: string; name: string; password: string }>(),
    'Sign Up Complete': props<{ user: firebase.auth.UserCredential }>(),
    'Sign Up Error': props<{ error: string }>(),
    'Sign Out': emptyProps(),
    'Sign Out Complete': emptyProps(),
    'Forgot Password': props<{ email: string }>(),
    'Forgot Password Complete': emptyProps(),
    'Forgot Password Error': props<{ error: string }>(),
    'Init Action Page': emptyProps(),
    'Init Action Page Error': emptyProps(),
    'Verify Email': props<{ oobCode: string }>(),
    'Verify Email Complete': emptyProps(),
    'Verify Email Error': props<{ error: string }>(),
    'Reset Password': props<{ oobCode: string }>(),
    'Reset Password Complete': emptyProps(),
    'Reset Password Error': props<{ error: string }>(),
  },
});
