import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { appActions } from 'src/app/modules/app-core/store/app.actions';
import { selectAppUser } from 'src/app/modules/app-core/store/app.selectors';
import { itemActions } from 'src/app/modules/app-items/modules/products-core/store';
import { authActions } from 'src/app/modules/auth/modules/auth-core/store/auth.actions';
import { AlertsService } from 'src/app/modules/shared/modules/alerts/services/alerts.service';
import { environment } from 'src/environments/environment';
import { CartStorageService } from '../services/cart-storage.service';
import { CartService } from '../services/cart.service';
import { cartActions } from './cart.actions';
import {
  selectCartIsLoaded,
  selectCartIsLoading,
  selectCartItems,
} from './cart.selectors';

@Injectable()
export class CartEffects {
  clearState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.signOutComplete, authActions.signInComplete),
      map(() => cartActions.clearState())
    );
  });

  loadOnInitComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.initComplete),
      map(() => cartActions.load({ allItems: true }))
    )
  );

  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartActions.load),
      concatLatestFrom(() => [
        this.store.select(selectCartIsLoading),
        this.store.select(selectCartIsLoaded),
      ]),
      filter(
        ([{ force }, isLoading, isLoaded]) => !isLoading && (force || !isLoaded)
      ),
      map(() => cartActions.loadStart())
    );
  });

  loadAllItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartActions.load, cartActions.add, cartActions.changeQuantity),
      filter((d) => d.type !== '[Cart] Load' || d.allItems),
      map(() => itemActions.loadAllItems({}))
    );
  });

  // loadProducts$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(cartActions.load),
  //     map(() => productActions.loadAllItems({}))
  //   );
  // });

  loadStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartActions.loadStart),
      concatLatestFrom(() => [this.store.select(selectAppUser)]),
      exhaustMap(([, user]) =>
        Boolean(user?.uid)
          ? this.cartService.getItems(user.uid)
          : this.cartStorageService.getItems()
      ),
      map((items) => cartActions.loadSuccess({ items })),
      catchError(() => {
        this.alertService.error('Failed to load Cart Items').subscribe();
        return of(cartActions.loadError());
      })
    );
  });

  loadSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartActions.loadSuccess),
      filter(({ items }) => items.length > 0),
      map(() => itemActions.loadAllItems({}))
    );
  });

  updateTrigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        cartActions.add,
        cartActions.remove,
        cartActions.removeAll,
        cartActions.changeQuantity
      ),
      concatLatestFrom(() => [this.store.select(selectCartIsLoading)]),
      filter(([, isLoading]) => !isLoading),
      debounceTime(environment.production ? 6000 : 0),
      map(() => cartActions.update())
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cartActions.update),
      concatLatestFrom(() => [
        this.store.select(selectCartItems),
        this.store.select(selectAppUser),
      ]),
      switchMap(([, cartItems, user]) =>
        Boolean(user?.uid)
          ? this.cartService.updateItems(user.uid, cartItems)
          : this.cartStorageService.updateItems(cartItems)
      ),
      map(() => cartActions.updateComplete()),
      catchError(() => of(cartActions.updateComplete()))
    );
  });

  constructor(
    private store: Store,
    private actions$: Actions,
    private cartService: CartService,
    private alertService: AlertsService,
    private cartStorageService: CartStorageService
  ) {}
}
