import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IItem, IItemDisplay } from '../interfaces/app-item.model';
import { featureKey } from './state';

export const itemActions = createActionGroup({
  source: featureKey,
  events: {
    'Load Featured Items': props<{ force?: boolean }>(),
    'Load Featured Items Start': emptyProps(),
    'Load Featured Items Complete': props<{ items: IItemDisplay[] }>(),
    'Load Featured Items Error': emptyProps(),
    'Load All Items': props<{ force?: boolean }>(),
    'Load All Items Start': emptyProps(),
    'Load All Items Complete': props<{
      items: IItemDisplay[];
      success: boolean;
    }>(),
    'Load Item By Id': props<{ id: string; force?: boolean }>(),
    'Load Item By Id Start': props<{ id: string }>(),
    'Load Item By Id Complete': props<{
      id: string;
      item: IItem;
      success: boolean;
    }>(),
    'Load Current Item': emptyProps(),
    'Add Item': props<{ item: IItem }>(),
    'Update Item': props<{ id: string; item: Partial<IItem> }>(),
    'Delete Item': props<{ id: string }>(),
  },
});
