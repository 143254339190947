import { createReducer, on } from '@ngrx/store';
import { appActions } from './app.actions';
import { IAppState, initialState } from './app.state';

const reducer = createReducer(
  initialState,
  on(appActions.loadImageUrlComplete, (state, action) => ({
    ...state,
    images: { ...state.images, [action.path]: action.url },
    imagesLoading: { ...state.imagesLoading, [action.path]: false },
  })),
  on(appActions.loadImageUrlError, (state, action) => ({
    ...state,
    imagesLoading: { ...state.imagesLoading, [action.path]: false },
  })),
  on(appActions.setUser, (state, action) => ({
    ...state,
    user: action.user || null,
  })),
  on(appActions.initComplete, (state) => ({
    ...state,
    hasInit: true,
  })),
  on(appActions.clearState, (): IAppState => initialState)
);

export function AppReducer(state: IAppState, action: any) {
  return reducer(state, action);
}
