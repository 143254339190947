<div
  *ngIf="showLoader$ | async"
  class="app-page-loader"
  [ngClass]="{ 'app-page-loader__transparent': isTransparent$ | async }"
>
  <div class="loading-container">
    <div class="loading"></div>
    <div id="loading-text">Please Wait..</div>
  </div>
</div>
