import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IItem, IItemDisplay } from '../interfaces/app-item.model';

@Injectable({ providedIn: 'root' })
export class ItemsApiService {
  constructor(private afs: AngularFirestore) {}

  getAllItems(): Observable<Record<string, IItemDisplay>> {
    return this.afs
      .collection('site-data')
      .doc('items')
      .get()
      .pipe(map((data) => data.data() as Record<string, IItemDisplay>));
  }

  getItem(id: string): Observable<IItem> {
    return this.afs
      .collection('items')
      .doc(id)
      .get()
      .pipe(map((data) => data.data() as IItem));
  }

  getFeaturedItems(): Observable<Record<string, IItemDisplay>> {
    return this.afs
      .collection('site-data')
      .doc('items-featured')
      .get()
      .pipe(map((data) => data.data() as Record<string, IItemDisplay>));
  }
}
